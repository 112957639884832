<template>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-12'>

            <div class='p-0 h-full flex flex-column'>

                <div class='grid'>

                <div class='col-12 lg:col-9'>
                <h5 class='mb-0 mt-3'><i class="pi pi-check mr-2"></i> Taşeron Listesi</h5>
                </div>

                <div class='col-12 lg:col-3 text-right'>

                <a href='#/Firm/Add'>
				<Button label='Taşeron Ekle' icon='pi pi-plus' class='p-button-danger mr-2'></Button>	
                </a>	

                <Button label='Excele Aktar' icon='pi pi-file-excel' class='p-button-success'></Button>
                </div>

                </div>

                <DataTable
                    :value='dataList'
                    :paginator='true'
                    class='p-datatable-gridlines'
                    :rows='6'
                    dataKey='id'
                    :rowHover='true'
                    v-model:filters='filters'
                    filterDisplay='menu'
                    :loading='loadingIndicator'
                    :filters='filters'
                    responsiveLayout='scroll'
                    :globalFilterFields="['name']">

                    <template #header>
                        <div class='flex justify-content-between flex-column sm:flex-row'>
                            <Button type='button' icon='pi pi-filter-slash' label='Temizle'
                                    class='p-button-outlined mb-2' @click='clearFilter()' />
                            <span class='p-input-icon-left mb-2'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Arama' style='width: 100%' />
                            </span>
                        </div>
                    </template>

                    <template #empty>
                        Veri bulunamadı.
                    </template>

                    <template #loading>
                        Taşeron firma listesi yükleniyor. Lütfen bekleyin.
                    </template>


                    <Column field='name' header='Firma İsmi' filterField='name' :showFilterMatchModes='false'>
                    </Column>



                    <Column field='cityName' header='Şehir' filterField='cityName' :showFilterMatchModes='false' style='width:8rem'>
                    </Column>

                     <Column field='townName' header='İlçe' filterField='townName' :showFilterMatchModes='false' style='width:8rem'>
                    </Column>


                    <Column field='taxOffice' header='Vergi Dairesi' filterField='taxOffice' :showFilterMatchModes='false' style='width:8rem'>
                    </Column>

                    <Column field='taxNumber' header='Vergi No' filterField='taxNumber' :showFilterMatchModes='false' style='width:8rem'>
                    </Column>

                    <Column field='personName' header='Yetkili' filterField='personName' :showFilterMatchModes='false' style='width:12rem'>
                    </Column>

                    <Column field='personPhone' header='Yetkili GSM' filterField='personPhone' :showFilterMatchModes='false' style='width:8rem'>
                        <template #body='{data}'>
                            <a :href="'tel:+90' + data.personPhone" v-if='data.personPhone != null && data.personPhone != ""'>{{ data.personPhone }}</a>
                        </template>
                    </Column>

                     <Column field='personPhone' header='WhatsApp' bodyClass='text-center' filterField='personPhone' :showFilterMatchModes='false' style='width:6rem'>
                        <template #body='{data}'>
                            <a :href="'https://wa.me/+90' + data.personPhone" v-if='data.personPhone != null && data.personPhone != ""' target="_blank">
                                <Button icon='pi pi-whatsapp' v-if='data.personPhone != null && data.personPhone != ""' class='p-button-rounded p-button-success'/>
                            </a>           
                        </template>
                    </Column>


                    <Column field='personEmail' header='Yetkili E-Posta' filterField='personEmail' :showFilterMatchModes='false' style='width:12rem'>
                        <template #body='{data}'>
                            <a :href="'mailto:' + data.personEmail" v-if='data.personEmail != null && data.personEmail != ""'>{{ data.personEmail }}</a>
                        </template>
                    </Column>

                    <Column header='Oluşturma Tarihi' filterField='insertDate' dataType='date' style='width:10rem'>
                        <template #body='{data}'>
                            {{ formatDate(data.insertDate) }}
                        </template>
                    </Column>

                    <Column field='verified' header='Durum' dataType='boolean' bodyClass='text-center' style='width:6.5rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isActive, 'text-pink-500 pi-times-circle': !data.isActive}"></i>
                        </template>

                    </Column>

                    <Column header='Düzenle' bodyClass='text-center' style='width:6.5rem' :exportable='false'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='p-button-rounded p-button-primary' @click='edit(slotProps.data)'/>
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>

    </div>


</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import FirmService from '../../services/firmService';
import store from '@/store';

export default {
    data() {
        return {
            dataList: [],
            filters: null,
            loadingIndicator: true,
        };
    },
    _firmService: null,
    created() {
        this._firmService = new FirmService();
        this.initFilters();
        this.loadingIndicator = true;
    },
    async mounted() {
        let projectId = store.getters.getActiveProject.id;
        const response = await this._firmService.getAllFirmsWithProjectId(projectId);
        this.dataList = response.data;
        this.loadingIndicator = false;
    },
    methods: {
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:mm');
        },
        edit(item) {
            this.$router.push({ name: 'editFirm', params: { firmId: item.id } });
        },
    },
};
</script>

<style scoped lang='scss'>
//@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}
</style>
